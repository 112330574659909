import React from 'react';
import {
  ColorDataTableCode,
  Link,
  Paragraph,
  PlatformTab,
  Section,
} from 'docComponents';

const WebTab = ({ data }) => {
  return (
    <PlatformTab>
      <Paragraph>
        Use the{' '}
        <Link href="/components/select-mark" isDesignCodeLink>
          select mark component
        </Link>{' '}
        in multi-select situations. Otherwise use the values below.
      </Paragraph>

      <Section title="Light Environment">
        <ColorDataTableCode
          env="light"
          colorsType="light"
          data={data}
          platform="web"
        />
      </Section>

      <Section title="Dark Environment">
        <ColorDataTableCode
          env="dark"
          colorsType="dark"
          data={data}
          platform="web"
        />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
